import React from "react"
import { Fragment } from "react"
import Footer from "./Footer"
import Head from "./Head"
import MainState from "./MainState"
import Navbar from "./Navbar"

const Layout = ({ children }) => {
  return (
    <Fragment>
      <MainState>
        <main id="main">
          <Head />
          <Navbar />
          {children}
          <Footer />
        </main>
      </MainState>
    </Fragment>
  )
}

export default Layout

import React, { createRef } from "react"
import MainContext from "./MainContext"

const MainState = props => {
  const treningiRef = createRef()

  const scrollTo = page => {
    treningiRef.current.scrollIntoView({ behavior: "smooth" })
  }
  return (
    <MainContext.Provider value={{ scrollTo, treningiRef }}>
      {props.children}
    </MainContext.Provider>
  )
}

export default MainState

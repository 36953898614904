import React, { createRef, useContext } from "react"
import logo from "../../static/logo3.png"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import Drawer from "@material-ui/core/Drawer"
import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import MainContext from "./MainContext"

const Navbar = () => {
  const mainContext = useContext(MainContext)
  const { scrollTo } = mainContext
  const useStyles = makeStyles({
    list: {
      width: 220,
    },
    fullList: {
      width: "auto",
    },
  })

  const classes = useStyles()
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {["galerija", "pasovi", "mojstri", "klub", "treningi"].map(
          (text, index) => (
            <Link to={text === "domov" ? `/` : `/${text}`}>
              <ListItem button key={text}>
                <ListItemText primary={text} />
              </ListItem>
            </Link>
          )
        )}
      </List>
    </div>
  )
  return (
    <nav class="main-nav-container">
      <div class="akc-logo">
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
      </div>
      <ul class="akc-navbar-ul">
        <li>
          <a onClick={() => scrollTo()}>Treningi</a>
        </li>
        <li>
          <Link to="/klub">Klub</Link>
        </li>
        <li>
          <Link to="/mojstri">Mojstri</Link>
        </li>
        <li>
          <Link to="/">Galerija</Link>
        </li>{" "}
        <li>
          <Link to="/pasovi">Pasovi</Link>
        </li>
      </ul>
      <React.Fragment key={"left"}>
        <Button class="menu-button-mini" onClick={toggleDrawer("left", true)}>
          <i class="fas fa-bars"></i>
        </Button>
        <Drawer
          anchor="left"
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
        >
          {list("left")}
        </Drawer>
      </React.Fragment>
      <div class="akc-navbar-social">
        <div class="akc-navbar-social-contact">
          <i class="fas fa-phone"></i>
          <p>+386 41 539 439</p>
        </div>
        <div class="akc-navbar-social-icons">
          <a href="">
            <i class="fab fa-facebook-square"></i>
          </a>
          <a href="">
            {" "}
            <i class="fab fa-instagram-square"></i>
          </a>
        </div>
      </div>
    </nav>
  )
}

export default Navbar

import { Link } from "gatsby"
import React from "react"
import logo from "../img/logo3.png"

const Footer = () => {
  return (
    <footer class="kwd-footer">
      <div class="kwd-footer-container">
        <div class="kwd-footer-left">
          <img src={logo} alt="" />
          <h2>Aikido klub Celje</h2>
          <p>Lopata 12, 3000 Celje</p>
        </div>
        <div class="kwd-footer-mid">
          <h2>Informacije javnega značaja</h2>
          <p>Aikido klub Celje je klub v javnem interesu.</p>
          <br />
          <Link>
            <p>Povezava</p>
          </Link>
        </div>
        <div class="kwd-footer-right">
          <h2>Kontakt</h2>
          <p>Uroš Jezernik - 041 539 439</p>
          <p>aikido.klub.celje@gmail.com</p>
        </div>
        <h1>Aikido klub Celje 2021 &copy;</h1>
      </div>
    </footer>
  )
}

export default Footer
